import React, { useEffect, useContext, useRef } from "react";
import Context from "../../context";

const Finishing = ({ data }) => {
  const { state, dispatch } = useContext(Context);
  const closeFinishing = () => {
    dispatch({ type: "TOGGLE_FINISHING", payload: !state.finishingActive });
  };
  let pStyle;
  if (state.isMobile) {
    pStyle = {
      backgroundImage: "none",
      padding: "15px 50px",
      justifyContent: "start",
    };
  }
  let toggleBtn = (
    <a
      className="btn btn-outline-light bebas abt-link"
      onClick={closeFinishing}
    >
      close
    </a>
  );
  const finishingWrap = useRef(null);

  return (
    <div
      className={`nwe-finishing-content ${state.finishingActive ? "show" : ""}`}
      ref={finishingWrap}
    >
      <div className={`col fpillar fmain`} style={data.backgC}>
        <img className="finishingImg" src={data.sourceUrl} alt={data.title} />
        <h3>{data.title}</h3>
        <p>{data.copy}</p>
        {!state.isMobile && toggleBtn}
      </div>
      <div className="col fpillar fcopy" style={pStyle}>
        <img className="finishingImg" src={data.fimage1} alt="" />
        <p>{data.fcopy1}</p>
      </div>
      <div className="col fpillar fcopy" style={pStyle}>
        <img className="finishingImg" src={data.fimage2} alt="" />
        <p>{data.fcopy2}</p>
        {state.isMobile && toggleBtn}
      </div>
    </div>
  );
};
export default Finishing;
