import React, { useContext, useReducer } from "react";
import "./App.scss";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { isMobile } from "react-device-detect";

import Context from "./context";
import reducer from "./reducer";

import Header from "./components/header/Header.component";
import Hero from "./components/hero/Hero.components";
import AboutUs from "./components/aboutUs/AboutUs.component";
import ContactUs from "./components/contactUs/ContactUs.component";
import Social from "./components/social/Social.component";
import Loader from "./components/loader/loader.component";

const client = new ApolloClient({
  uri: "http://staging.nwe.com/graphql",
  cache: new InMemoryCache(),
});

function App() {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  let body = document.body;
  let bodyClassCheck = body.classList.contains("locked");
  if (state.siteLoaded && bodyClassCheck) {
    setTimeout(() => {
      body.classList.remove("locked");
      dispatch({
        type: "RELEASE_LOADER",
        payload: true,
      });
    }, 750);
  }

  return (
    <ApolloProvider client={client}>
      <Context.Provider value={{ state, dispatch }}>
        <div className="container-fluid nwe-wrap">
          <div className={`loadState ${state.loaderRelease ? "loaded" : ""}`}>
            <Loader className="something" />
          </div>
          <Header isMobile={isMobile} />
          <Hero />
          <AboutUs />
          <ContactUs />
          <Social />
          <div className="footer-bar">
            <p>
              &copy; New Wave Entertainment <span>All Rights Reserved</span>
            </p>
          </div>
        </div>
      </Context.Provider>
    </ApolloProvider>
  );
}

export default App;
