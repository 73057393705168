import React, { useContext } from "react";
import { graphql } from "react-apollo";
import { aboutData } from "../../graphql/queries";
import Context from "../../context";
import Finishing from "./Finishing.component";

import "./AboutUs.styles.scss";

const AboutUs = ({ data: { loading, error, aboutUss, pillars } }) => {
  const { state, dispatch } = useContext(Context);
  const handleFinishing = () => {
    dispatch({ type: "TOGGLE_FINISHING", payload: !state.finishingActive });
  };
  if (error) return <h2>something went wrong</h2>;
  if (aboutUss && pillars) {
    //   NWE about us setup
    let aboutTitleNwe = aboutUss.edges[0].node.aboutUS.title;
    let aboutCopyNwe = aboutUss.nodes[0].content.replace("<p>", "");
    aboutCopyNwe = aboutCopyNwe.replace("</p>", "");
    let finishingContent = {
      title: null,
      backgC: null,
      sourceUrl: null,
      copy: null,
      fcopy1: null,
      fimage1: null,
      fcopy2: null,
      fimage2: null,
    };

    // Pillars setup
    let pillarsArr = pillars.edges.sort(() => Math.random() - 0.5);
    pillarsArr = pillarsArr.map((pillar, i) => {
      let currentPillar = pillar.node;
      let backgC = {
        backgroundColor: currentPillar.pillarMeta.backgroundColor,
      };

      let copy = currentPillar.content.replace("<p>", "");
      copy = copy.replace("</p>", "");
      let link;
      if (currentPillar.pillarMeta.link === null) {
        finishingContent.copy = copy;
        finishingContent.fcopy1 = currentPillar.pillarMeta.extendedInfo;
        finishingContent.fimage1 =
          currentPillar.pillarMeta.extendedInfoImage.sourceUrl;
        finishingContent.fcopy2 = currentPillar.pillarMeta.extendedInfoCopy;
        finishingContent.fimage2 =
          currentPillar.pillarMeta.extendedInfoImage2.sourceUrl;
        finishingContent.backgC = backgC;
        finishingContent.sourceUrl = currentPillar.pillarMeta.logo.sourceUrl;
        finishingContent.title = currentPillar.title;
        link = (
          <button
            className="btn btn-outline-light bebas abt-link"
            onClick={handleFinishing}
          >
            {currentPillar.pillarMeta.linkLabel}
          </button>
        );
      } else {
        link = (
          <a
            href={currentPillar.pillarMeta.link}
            className="btn btn-outline-light bebas abt-link"
            rel="noopener noreferrer"
            target={"_blank"}
          >
            {currentPillar.pillarMeta.linkLabel}
          </a>
        );
      }
      return (
        <div
          className={`col pillar ${currentPillar.title.replace(".agency", "")}`}
          style={backgC}
          key={i}
        >
          <img
            src={currentPillar.pillarMeta.logo.sourceUrl}
            alt={currentPillar.title}
          />
          <h3>{currentPillar.title}</h3>
          <p>{copy}</p>
          {link}
        </div>
      );
    });
    let pWrapStyles;
    if (state.finishingActive && state.isMobile) {
      pWrapStyles = {
        height: "2200px",
      };
    } else if (state.finishingActive && !state.isMobile) {
      pWrapStyles = {
        height: "1100px",
      };
    } else {
      pWrapStyles = {
        height: "auto",
      };
    }
    return (
      <div className="row nwe-about-us" id="who">
        <div className="row justify-content-center intro">
          <span>
            <h2>{aboutTitleNwe}</h2>
          </span>
        </div>
        <div className="col col-med-6 align-self-center nwe-copy">
          <span>{aboutCopyNwe}</span>
        </div>
        <div
          className={`row pillars-wrap ${state.isMobile ? "mobile" : ""}`}
          style={pWrapStyles}
        >
          {state.finishingActive && <Finishing data={finishingContent} />}
          {!state.finishingActive && <div className="pw">{pillarsArr}</div>}
        </div>
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
};
export default graphql(aboutData)(AboutUs);
