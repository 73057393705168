import React from "react";

const DesktopHeader = () => {
  return (
    <div className="row nwe-header ">
      <div className="col nwe-header_links bebas">
        <a href="#who">Who we are</a>
        <a href="#contact">contact us</a>
      </div>
      <div className="col nwe-header_logo">
        <img
          src="./images/logos/NWE_logo_20200515.png"
          alt="New Wave Entertainment Logo"
          className="img-fluid "
        />
      </div>
      <div className="col nwe-header_social">
        <div className="row social_row">
          <a
            href="https://www.facebook.com/NewWaveEntertainment"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons col-3"
          >
            <img
              src="./images/logos/social-media/SocialMedia_FB_color.png"
              alt=""
            />
          </a>
          <a
            href="https://twitter.com/NWESocial"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons col-3"
          >
            <img
              src="./images/logos/social-media/SocialMedia_TW_color.png"
              alt=""
            />
          </a>
          <a
            href="https://www.instagram.com/nwesocial/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons col-3"
          >
            <img
              src="./images/logos/social-media/SocialMedia_IG_color.png"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/new-wave-entertainment/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons col-3"
          >
            <img
              src="./images/logos/social-media/SocialMedia_LI_color.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
